import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

// Projects images
import box from "../../Assets/Projects/box.png";
import library from "../../Assets/Projects/library.png";
import sql from "../../Assets/Projects/sql.png";
import youtube from "../../Assets/Projects/youtube.png";
import hangman from "../../Assets/Projects/hangman.png";
import zoo from "../../Assets/Projects/zoo.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={box}
              isBlog={false}
              title="Data Structure Box Management app WPF"
              description="My project demonstrate using two linked generics data structures ,BST and Doubly linked list. The app manage a boxes store storage and can add remove and give best offer for squared bottomed box represented by X as width and length and Y as height. It stores the data for the boxes in doubly dimensional BST with key and value and Date descending order linked list."
              ghLink="https://github.com/itayG98/Box-Data-Structure-app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={zoo}
              isBlog={false}
              title="Zoo ASP.NET web app"
              description="This web application is built using Asp.Net Core MVC and utilizes an MSSQL Docker image with Entity Framework 7, Identity, and Bootstrap. The app allows users to scroll through and choose animals to explore and leave comments."
              ghLink="https://github.com/itayG98/Zoo-Catalog-App/tree/master/Zoo-Blog-master"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={library}
              isBlog={false}
              title="Libary Management UWP app"
              description="My app main purpose is to manage a libary costumers and items add let users order buy and return from the library's stock. It has 2 types of users  Costumer and Employee."
              ghLink="https://github.com/itayG98/Libary-managment-UWP-App"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hangman}
              isBlog={false}
              title="Hangman UWP app"
              description="I made a simple UWP Windows Desktop App using C# and .Net framwork I created a class for the game , A Jason text file from the internet with 500 english names and constructed a XAML page divided into three main parts : Hangman draw , the game ststus and a virtual keyboard . My game response also to keyboard pressing."
              ghLink="https://github.com/itayG98/HangMan-Game"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={youtube}
              isBlog={false}
              title="Ai and Data Science Python materials for lectures"
              description="I'm currently teaching Python AI and data science introduction to youth group and here is my jupyter notebooks examples collection  in order to maximize my students learning"
              ghLink="https://github.com/itayG98/PyAi_Material"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sql}
              isBlog={false}
              title="SQL, ERD Design, Table-Schema and complex queries "
              description="In this assay i made both Table Schema and ERD Diargram for a real estate company software's Data Base according to client's requirements"
              ghLink="https://github.com/itayG98/Sale-My-House"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
