import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone,my name is <span className="purple">Itay Getahun </span>
            from Israel.
            <br />
            Software developer graduated from Sela college and{" "}
            <span className="purple"> Python AI teacher </span> to youth group
            in Acharay TECH <br /> <br />
            I'm seeking for a challenging software development position where I
            can utilize my skills and continue to grow and develop as a
            professional. <br />
            <br />I am pursuing a{" "}
            <span className="purple">
              B.A. in Computer-Science and Cognitive-Science{" "}
            </span>
            at Israel's Open university.
            <br />
            <br />
          </p>

          <p style={{ color: "rgb(155 126 172)" }}>
            "We are what we repeatedly do. Excellence, then, is not an act, but
            a habit!"{" "}
          </p>
          <footer className="blockquote-footer">Aristotle</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
