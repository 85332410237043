import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import Type from "./Type";
import Tilt from "react-parallax-tilt";
import myImg from "../../Assets/avatar_black.svg";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi There!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                I'M
                <strong className="main-name"> ITAY GETAHUN</strong>
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <Container>
          <Row>
            <Col md={4} className="myAvtar">
              <Tilt>
                <img src={myImg} className="img-fluid" alt="avatar" />
              </Tilt>
            </Col>
            <Col md={8} className=" home-content home-about-description">
              <h1 style={{ fontSize: "3em" }}>
                LET ME <span className="purple"> INTRODUCE </span> MYSELF
              </h1>
              <p className="home-about-body">
                <span className="purple"> Software development graduate </span>
                from Sela College with a strong foundation in{" "}
                <span className="purple"> C#, Python </span> , ASP.NET, Fast API
                ,and Angular.
                <br />
                Currently teaching{" "}
                <span className="purple"> Python AI and data science</span>{" "}
                introduction to youth group at acharay TECH
                <br />
                <br />
                My field of Interest's are building new &nbsp;
                <i>
                  <b className="purple">
                    Web and software based Products, Machine learning based
                    serveices{" "}
                  </b>{" "}
                  and also in areas related to{" "}
                  <b className="purple">
                    Deep Learning and Natural Launguage Processing.
                  </b>
                </i>
                <br />
                <br />
                Whenever possible, I also apply my passion for developing
                frontend web application with
                <i>
                  <b className="purple">
                    {" "}
                    Modern Javascript Library and Frameworks
                  </b>
                </i>
                &nbsp; like
                <i>
                  <b className="purple"> React.js and Angualr</b>
                </i>
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Home;
